<template id="work-history">
  <div>
    <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
        <div class="title">   {{$t("message.worksPlace")}}</div>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadWorkPlaces"
      >
        <table class="w-100 td20">
          <tr>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.nameGiven")}} </strong>
            </td>

            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.position")}} </strong>
            </td>

            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.manager")}} </strong>
            </td>

            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.salary")}} </strong>
            </td>
            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'"> {{$t("message.status")}}  </strong>
            </td>

            <td>
              <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.data_from_to")}} </strong>
            </td>

            <!-- <td>
                            <strong class="mr-1 font-bold"> Причина </strong>
                        </td> -->
          </tr>

          <tr
            v-for="(work_history, index) in staffWorkHistory"
            :key="'work_place-' + index"
          >
            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span> {{ work_history.company }} </span>
              </p>
            </td>

            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span> {{ work_history.position }} </span>
              </p>
            </td>

            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span>
                  {{ work_history.manager }}
                </span>
              </p>
            </td>

            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span>
                  {{ work_history.amount | formatNumber }} {{ work_history.amount_type }}
                </span>
              </p>
            </td>
            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span>
                  {{ work_history.status?work_history.status.name:'' }}
                </span>
              </p>
            </td>
            <td>
              <p :class="mode ? 'text__day2' : 'text__night2'" class="font-medium mb-3 mt-3">
                <span>
                  {{ work_history.started_date }}
                  <span></span>
                  <span></span>
                  {{ work_history.finished_date }}
                </span>
              </p>
            </td>
            <td class="text-right">
                <el-row>
                  <!-- <el-button
                      type="primary"
                      icon="el-icon-edit"
                      circle
                      size="small"
                      @click="editStaffWorkPlace(work_place)"
                  ></el-button> -->

                  <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="small"
                      @click="deleteWorkHistory(work_history)"
                      c-can="'profile.experience.destroy'"
                  ></el-button>
                </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "work-history",
  components: {},
  data() {
    return {
      form: {},
      loadWorkPlaces: false,
      workPlacesCreate: false,
      workPlacesUpdate: false,
    };
  },
  created() {
    this.fetchStaffWorkHistory();
  },
    
  computed: {
    ...mapGetters({
      staffWorkHistory: "profile/staff_work_history",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getStaffWorkHistory: "profile/getStaffWorkHistory",
      deleteStaffWorkHistory: "profile/deleteStaffWorkHistory",
    }),

    fetchStaffWorkHistory() {
      if (!this.loadWorkPlaces && this.$route.params.id) {
        this.loadWorkPlaces = true;
        this.getStaffWorkHistory(this.$route.params.id)
          .then((res) => {
            this.loadWorkPlaces = false;
          })
          .catch((err) => {
            this.loadWorkPlaces = false;
          });
      }
    },
    refresh() {
      this.fetchStaffWorkHistory();
    },
    open(work_place) {
      this.form = JSON.parse(JSON.stringify(work_place));
      this.workPlacesUpdate = true;
    },
    deleteWorkHistory(work_history) {
          this.$confirm(
              "Вы действительно хотите это сделать?",
              "Предупреждение",
              {
                  confirmButtonText: "Да",
                  cancelButtonText: "Отмен",
                  type: "warning",
              }
          )
              .then(() => {
                  if (!this.loadWorkPlaces && this.$route.params.id) {
                      this.loadWorkPlaces = true;
                      let form = {
                          staff_id: this.$route.params.id,
                          work_history_id: work_history.id,
                      };
                      this.deleteStaffWorkHistory(form)
                          .then((res) => {
                              this.loadWorkPlaces = false;
                              this.$alert(res);
                              if (res.status == 200) {
                                this.fetchStaffWorkHistory();
                              }
                          })
                          .catch((err) => {
                              this.loadWorkPlaces = false;
                          });
                  }
              })
              .catch(() => {
                  this.$message({
                      type: "warning",
                      message: "Операция отменена",
                  });
              });
      },
  },
};
</script>

